.leaflet-container {
  height: 500px;
}

$primary-color-dark:   #689F38;
$primary-color:        #8BC34A;
$primary-color-light:  #DCEDC8;
$primary-color-text:   #212121;
$accent-color:         #4CAF50;
$primary-text-color:   #212121;
$secondary-text-color: #757575;
$divider-color:        #BDBDBD;
$white: rgba(255, 255, 255, 255);
$offwhite: #EEE;
$red: #f77a7a;

body {
  margin: 0;
  font-family: 'Armata', sans-serif;
  line-height: 1.6;
  font-size: 1rem;
  position: relative;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("/assets/forest1.jpeg") no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  color: $offwhite;
  padding: 1.5rem;
}

nav {
  display: flex;
  align-content: center;
  align-items: center;
  height: 4rem;
  font-size: 20px;
  font-weight: bold;
  padding-left: 50px;
  padding-right: 3rem;
  font-family: 'Handlee', cursive;
  .title {
    white-space: nowrap;
    font-family: 'Pacifico', cursive;
    font-weight: normal;
    color: $primary-color;
    .subtext {
      position: absolute;
      font-size: 1rem;
      margin-left: -10.3rem;
      margin-top: 2.4rem;
    }
  }
}

.nav-left {
  margin-right: 2rem;
}

.nav-center, .nav-right {
  margin-top: 1rem;
  margin-left: auto;
  .nav-item {
    white-space: nowrap;
  }
}

.nav-item {
  &.small {
    font-size: .7rem;
    color: $divider-color;
  }
}

a {
  cursor: pointer;
  color: $primary-color;
  text-decoration: none;
  &:hover {
    color: $accent-color;
  }
  &.nav-item {
    margin-right: 1.5rem;
  }
}

.button {
  background-color: $white;
  margin: 3px;
  border: 1px solid;
  border-color: $white;
  border-radius: 3px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  white-space:nowrap;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    border-color: $primary-color;
  }
  &:active {
    border-color: $accent-color;
  }
  &.primary {
    color: $white;
    background-color: $accent-color;
  }
  &.danger {
    color: $white;
    background-color: $red;
    &:hover, &:active {
      border-color: $red;
    }
  }
}

.input {
  padding-top: .05rem;
  padding-bottom: .05rem;
  padding-left: .6rem;
  padding-right: .6rem;
  width: 280px;
  font-size: 1rem;
  height: 2.25em;
  border: 1px solid #dbdbdb;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: 3px;
}

article {
  max-width: 650px;
  font-weight: bold;
  margin: auto;
  padding: 2rem;
}

.map-actions {
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: 1rem;
  button {
    &.secondary {
      margin-left: auto;
    }
  }
}

form {
  width: 325px;
  margin: auto;
}

table {
  width: 100%;
  margin: auto;
  border-spacing: 0;
}

td, th {
  padding-left: 10px;
  border: none;
  height: 3rem;
}

th {
  text-align: left;
  font-weight: bold;
}

td {
  text-align: left;
  .button {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: .8rem;
  }
}

tr:nth-child(even) td {
  background-color: rgba(255, 255, 255, 0.1);
}

tr:nth-child(odd) td {
  background-color: rgba(255, 255, 255, 0.05);
}

.delete {
  display: inline;
  font-size: 1rem;
  cursor: pointer;
  color: $red;
}

.photo {
  margin: .4rem;
}

.photo:last-child {
  margin-bottom: 1rem;
}

.login {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;
}

section {
  flex-grow: 1;
  width: 100%;
}
